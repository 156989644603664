import { useCallback, useEffect, useState } from "react";

export default function usePlatform() {
  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);

  const check = useCallback((width: number) => {
    if (width <= 768) {
      return "mobile";
    }

    return "desktop";
  }, []);

  return { deviceType: check(width) };
}
