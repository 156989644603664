import { Dimensions, StyleSheet } from "react-native";

export const calc = () => {
  const { width, height } = Dimensions.get("window");
  const w = (val: number) => (val / rW) * width;
  const h = (val: number) => (val / rH) * height;

  return { w, h };
};

/**
 * Creates style based on reference width and reference height.
 *
 * This normalises the values from Figma to the screen.
 *
 * @param rW
 * @param rH
 * @returns
 */
export const createStyle = (rW: number = 390, rH: number = 844) => {
  const { width, height } = Dimensions.get("window");
  const w = (val: number) => (val / rW) * width;
  const h = (val: number) => (val / rH) * height;

  const styles = StyleSheet.create({
    header: {
      height: h(625),
    },
    headerTitle: {
      width: w(380.92),
      height: h(82.32),
      fontFamily: "Heebo",
      fontWeight: "900",
      fontSize: h(26.9),
      lineHeight: h(39),
      textAlign: "center",
      zIndex: 2,
      textShadowColor: "rgba(0, 0, 0, 0.8)",
      textShadowOffset: { width: w(1.6), height: h(5.8) },
      textShadowRadius: w(14),
      color: "white",
      position: "absolute",
      top: h(301),
      left: w(5),
    },
    headerArrow: {
      width: w(46.23),
      height: h(39.7),
      marginLeft: w(27),
    },
    headerArrowTip: {
      fontFamily: "Cutive",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: h(10),
      lineHeight: h(11),
      textAlign: "center",
      color: "#D67732",
      transform: [{ rotate: "-11.42deg" }],
      marginTop: h(26.93),
    },
    arrowTipContainer: {
      width: w(110.98),
      height: h(44.04),
      position: "absolute",
      top: h(377),
      left: w(279),
    },
    process: {
      width: w(390),
      height: h(768),
      backgroundColor: "white",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
    },
    processTitle: {
      width: w(390),
      height: h(83.6),
      top: h(96),
      fontFamily: "Heebo",
      fontStyle: "normal",
      fontWeight: "900",
      fontSize: h(27),
      lineHeight: h(40),
      alignItems: "center",
      textAlign: "center",
      color: "#000000",
    },
    processFrame: {
      width: w(368.07),
      height: h(127.96),
      display: "flex",
      alignContent: "space-between",
      justifyContent: "space-between",
      flexDirection: "row",
      marginBottom: h(55.45),
    },
    processFrameImage: {
      width: w(129),
      aspectRatio: 1,
      borderRadius: w(34),
    },
    processFrameDescription: {
      display: "flex",
      flexDirection: "column",
      marginTop: h(27),
      width: w(210.69),
      height: h(51.18),
    },
    processFrameDescriptionTitle: {
      fontFamily: "Heebo",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: h(16),
      lineHeight: h(23),
    },
    processFrameDescriptionMessage: {
      fontFamily: "Heebo",
      fontStyle: "normal",
      fontWeight: "100",
      fontSize: h(16),
      lineHeight: h(23),
    },
    processFrameDisclaimer: {
      marginTop: h(66),
      fontFamily: "Heebo",
      fontStyle: "normal",
      fontWeight: "100",
      fontSize: h(14),
      lineHeight: h(15),
    },
    processFrameSideComment: {
      position: "absolute",
      top: h(261),
      left: w(172),
      width: w(132.95),
      height: h(48.48),
    },
    processFrameSideCommentMessage: {
      position: "absolute",
      top: 0,
      right: 0,
      fontFamily: "Cutive",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: h(13.6),
      lineHeight: h(15),
      textAlign: "center",
      color: "#D67732",
      transform: [{ rotate: "-3.71deg" }],
      width: w(104.26),
      height: h(29),
    },
    processFrameSideCommentArrow: {
      position: "absolute",
      bottom: 0,
      left: 0,
      width: w(33.42),
      height: h(20.74),
    },
    team: {
      height: h(1007),
      backgroundColor: "rgba(214, 119, 50, 0.3)",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: h(37),
    },
    member: {
      width: w(326),
      height: h(266),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      alignContent: "center",
      justifyContent: "space-between",
    },
    memberImage: {
      width: h(171),
      aspectRatio: 1,
      borderRadius: w(85),
    },
    memberText: {
      fontFamily: "Heebo",
      fontStyle: "normal",
      fontWeight: "100",
      fontSize: h(16),
      lineHeight: h(18),
      textAlign: "center",
    },
    memberMarginTop: { marginTop: h(55.87) },
    histogram: {
      width: w(409.87),
      height: h(302),
    },
  });
  return styles;
};

// Desktop reference
const rW = 390;
const rH = 844;

const styles = createStyle(rW, rH);

export default styles;
