import React from "react";
import { Image, Text, View } from "react-native";
import "./App.css";
import useFigma from "./hooks/useFigma";

function App() {
  const { styles } = useFigma();
  return (
    <View>
      <View style={styles.header}>
        <Image
          source={require("./assets/images/HeaderImage.png")}
          resizeMethod="scale"
          resizeMode="cover"
          style={[
            styles.header,
            {
              position: "absolute",
              width: "100%",
            },
          ]}
        />
        <Image
          source={require("./assets/images/LinearGradient.png")}
          resizeMethod="auto"
          resizeMode="stretch"
          style={[
            styles.header,
            {
              position: "absolute",
              width: "100%",
            },
          ]}
        />
        <Text style={styles.headerTitle}>
          Building mobile apps since{"\n"}Android Cupcake
        </Text>
        <View style={styles.arrowTipContainer}>
          <Image
            source={require("./assets/images/Arrow.png")}
            resizeMethod="auto"
            resizeMode="stretch"
            style={styles.headerArrow}
          />
          <Text style={styles.headerArrowTip}>That's 2009!</Text>
        </View>
      </View>
      <View style={styles.process}>
        <Text style={styles.processTitle}>The Process</Text>
        <View style={styles.processFrame}>
          <Image
            style={styles.processFrameImage}
            source={require("./assets/images/DesignImg.png")}
            resizeMethod="scale"
            resizeMode="cover"
          />
          <View style={styles.processFrameDescription}>
            <Text style={styles.processFrameDescriptionTitle}>Design</Text>
            <Text style={styles.processFrameDescriptionMessage}>
              You have an app idea, branding, and a design pack.
            </Text>
          </View>
        </View>
        <View style={styles.processFrame}>
          <View style={styles.processFrameDescription}>
            <Text style={styles.processFrameDescriptionTitle}>Develop</Text>
            <Text style={styles.processFrameDescriptionMessage}>
              We convert your brand identity and design pack into code.*
            </Text>
          </View>
          <Image
            style={styles.processFrameImage}
            source={require("./assets/images/DevImg.png")}
            resizeMethod="scale"
            resizeMode="cover"
          />
        </View>
        <View style={styles.processFrame}>
          <Image
            style={styles.processFrameImage}
            source={require("./assets/images/DepImg.png")}
            resizeMethod="scale"
            resizeMode="cover"
          />
          <View style={styles.processFrameDescription}>
            <Text style={styles.processFrameDescriptionTitle}>Deploy</Text>
            <Text style={styles.processFrameDescriptionMessage}>
              We launch your app and monitor it’s performance.
            </Text>
          </View>
        </View>
        <Text style={styles.processFrameDisclaimer}>
          *while downing gratuitous amounts of caffeine
        </Text>

        <View style={styles.processFrameSideComment}>
          <Image
            source={require("./assets/images/ArrowDown.png")}
            resizeMethod="auto"
            resizeMode="stretch"
            style={styles.processFrameSideCommentArrow}
          />
          <Text style={styles.processFrameSideCommentMessage}>
            This is where we come in
          </Text>
        </View>
      </View>
      <View style={styles.team}>
        <Text style={styles.processTitle}>The Team</Text>
        <View style={styles.member}>
          <Image
            style={styles.memberImage}
            resizeMethod="scale"
            resizeMode="cover"
            source={require("./assets/images/Kev.png")}
          />
          <Text style={styles.memberText}>
            A once Google-certified Android developer, Kevin was introduced to
            cross-platform native development in 2016. He is working on his
            masters in Applied Data Science with The University of Michigan.
          </Text>
        </View>
        <View style={[styles.member, styles.memberMarginTop]}>
          <Image
            style={styles.memberImage}
            resizeMethod="scale"
            resizeMode="cover"
            source={require("./assets/images/Tin.png")}
          />
          <Text style={styles.memberText}>
            With over 10 years experience in Software Quality Assurance, and 3
            years as a software developer, Cristine is a QA powerhouse.
            Attention to detail and ensuring good test coverage are her bread
            and butter.
          </Text>
        </View>
        <View style={[styles.member, styles.memberMarginTop]}>
          <Image
            style={styles.memberImage}
            resizeMethod="scale"
            resizeMode="cover"
            source={require("./assets/images/Mvio.png")}
          />
          <Text style={styles.memberText}>
            Maricris handles all the admin and bookkeeping. A true wine
            connoisseur, she enjoys travelling and dining.
          </Text>
        </View>
      </View>
    </View>
  );
}

export default App;
