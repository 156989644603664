import { Dimensions, StyleSheet } from "react-native";

export const calc = () => {
  const { width, height } = Dimensions.get("window");
  const w = (val: number) => (val / rW) * width;
  const h = (val: number) => (val / rH) * height;

  return { w, h };
};

/**
 * Creates style based on reference width and reference height.
 *
 * This normalises the values from Figma to the screen.
 *
 * @param rW
 * @param rH
 * @returns
 */
export const createStyle = (rW: number = 1440, rH: number = 1024) => {
  const { width, height } = Dimensions.get("window");
  const w = (val: number) => (val / rW) * width;
  const h = (val: number) => (val / rH) * height;

  const styles = StyleSheet.create({
    header: {
      height: h(900),
    },
    headerTitle: {
      width: w(907),
      height: h(196),
      fontFamily: "Heebo",
      fontWeight: "900",
      fontSize: h(64),
      lineHeight: 94,
      textAlign: "center",
      zIndex: 2,
      textShadowColor: "rgba(0, 0, 0, 0.8)",
      textShadowOffset: { width: w(4), height: h(14) },
      textShadowRadius: w(14),
      color: "white",
      position: "absolute",
      top: h(421),
      left: w(267),
    },
    headerArrow: {
      width: w(46.23),
      height: h(39.7),
      marginLeft: w(65),
    },
    headerArrowTip: {
      fontFamily: "Cutive",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: h(24),
      lineHeight: h(26),
      textAlign: "center",
      color: "#D67732",
      transform: [{ rotate: "-11.42deg" }],
      marginTop: h(26.93),
    },
    arrowTipContainer: {
      width: w(263.92),
      height: h(104.68),
      position: "absolute",
      right: w(302.08),
      bottom: h(192.32),
    },
    process: {
      height: h(1846),
      backgroundColor: "white",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
    },
    processTitle: {
      width: w(907),
      height: h(196),
      top: h(96),
      fontFamily: "Heebo",
      fontStyle: "normal",
      fontWeight: "900",
      fontSize: h(64),
      lineHeight: h(94),
      alignItems: "center",
      textAlign: "center",
      color: "#000000",
    },
    processFrame: {
      width: w(856),
      height: h(300),
      display: "flex",
      alignContent: "space-between",
      justifyContent: "space-between",
      flexDirection: "row",
      marginBottom: h(130),
    },
    processFrameImage: {
      width: h(300),
      aspectRatio: 1,
      borderRadius: h(80),
    },
    processFrameDescription: {
      display: "flex",
      width: w(490),
      height: h(170),
      flexDirection: "column",
      marginTop: h(65),
    },
    processFrameDescriptionTitle: {
      fontFamily: "Heebo",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: h(36),
      lineHeight: h(53),
    },
    processFrameDescriptionMessage: {
      fontFamily: "Heebo",
      fontStyle: "normal",
      fontWeight: "100",
      fontSize: h(36),
      lineHeight: h(53),
    },
    processFrameDisclaimer: {
      marginTop: h(157),
      fontFamily: "Heebo",
      fontStyle: "normal",
      fontWeight: "100",
      fontSize: h(24),
      lineHeight: h(35),
    },
    processFrameSideComment: {
      position: "absolute",
      top: h(680),
      left: w(520.28),
      width: w(308.74),
      height: h(113.62),
    },
    processFrameSideCommentMessage: {
      position: "absolute",
      top: 0,
      right: 0,
      fontFamily: "Cutive",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: h(32),
      lineHeight: h(35),
      textAlign: "center",
      color: "#D67732",
      transform: [{ rotate: "-3.71deg" }],
      width: w(242),
      height: h(70),
    },
    processFrameSideCommentArrow: {
      position: "absolute",
      bottom: 0,
      left: 0,
      width: w(77.72),
      height: h(48.62),
    },
    team: {
      height: h(2675),
      backgroundColor: "rgba(214, 119, 50, 0.3)",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
    },
    member: {
      width: w(759),
      height: h(619),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      alignContent: "center",
      justifyContent: "space-between",
    },
    memberImage: {
      width: h(400),
      aspectRatio: 1,
      borderRadius: w(200),
    },
    memberText: {
      fontFamily: "Heebo",
      fontStyle: "normal",
      fontWeight: "100",
      fontSize: h(28),
      lineHeight: h(41),
      textAlign: "center",
    },
    memberMarginTop: { marginTop: h(130) },
    histogram: {
      width: w(409.87),
      height: h(302),
    },
  });
  return styles;
};

// Desktop reference
const rW = 1440;
const rH = 1024;

const styles = createStyle(rW, rH);

export default styles;
