import { useCallback, useEffect, useState } from "react";
import { createStyle, calc } from "../figma/index.desktop";
import {
  calc as calcM,
  createStyle as createStyleM,
} from "../figma/index.handset";
import usePlatform from "./usePlatform";

const desktopStyle = createStyle();

type RealStyle = typeof desktopStyle;

export default function useFigma() {
  const { deviceType } = usePlatform();
  const [styles, setStyles] = useState<any>(
    deviceType === "mobile" ? createStyleM() : createStyle()
  );
  const handleWindowSizeChange = useCallback(() => {
    if (deviceType === "mobile") {
      setStyles(createStyleM());
    } else {
      setStyles(createStyle());
    }
  }, [deviceType]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);

  return {
    styles: styles as RealStyle,
    calc: deviceType === "mobile" ? calcM() : calc(),
  };
}
